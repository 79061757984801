import { useTranslation } from 'next-i18next';

import tw, { type TwStyle } from 'twin.macro';

import Button from '@components/elements/Button';
import { cssMerge } from '@utils/styleHelpers';

type Props = {
  isError?: boolean;
  isFetching?: boolean;
  onTryAgain?: () => Promise<unknown>;
  justify?: 'left' | 'center' | 'right';
  styles?: TwStyle;
};

const ErrorLoading = ({
  isError = false,
  isFetching = false,
  onTryAgain,
  justify = 'center',
  styles = {},
}: Props) => {
  const { t } = useTranslation();

  if (!isError) {
    return null;
  }

  const justifyStyle = {
    left: { wrapper: {}, btnWrapper: {} },
    center: { wrapper: tw`text-center`, btnWrapper: tw`mx-auto` },
    right: { wrapper: tw`text-right`, btnWrapper: tw`ml-auto` },
  }[justify];

  const twStyle = cssMerge({
    defaultCss: justifyStyle.wrapper,
    ...(typeof styles?.wrapper === 'object' && 'wrapper' in styles
      ? styles.wrapper
      : {}),
  });

  return (
    <div css={twStyle}>
      <div className="h2" tw="mb-0">
        {t('$*components.errorLoading.title')}
      </div>
      <p>{t('$*components.errorLoading.paragraph')}</p>
      {onTryAgain && (
        <div css={[tw`table mt-4`, justifyStyle.btnWrapper]}>
          <Button
            onClick={() => {
              onTryAgain();
            }}
            isLoading={isFetching}
            disabled={isFetching}
          >
            {t('$*components.errorLoading.tryAgain')}
          </Button>
        </div>
      )}
    </div>
  );
};

ErrorLoading.displayName = 'ErrorLoading';

export default ErrorLoading;
