import { type ReactNode } from 'react';
import type { TwStyle } from 'twin.macro';

import ErrorLoading from '@components/elements/Loading/ErrorLoading';
import LoadedWrapper from '@components/elements/Loading/LoadedWrapper';
import Loading from '@components/elements/Loading/Loading';

type Props = {
  children: ReactNode;
  isError?: boolean;
  isFetching?: boolean;
  isLoading?: boolean;
  refetch?: () => Promise<unknown>;
  showLoadingText?: boolean;
  withTimeoutInfo?: boolean;
  justify?: 'left' | 'center' | 'right';
  styles?: {
    loading?: TwStyle;
    error?: TwStyle;
  };
};

const ApiLoadingBoundary = ({
  children,
  isError = false,
  isFetching = false,
  isLoading = false,
  refetch,
  showLoadingText = true,
  withTimeoutInfo = true,
  justify = 'center',
  styles = {},
}: Props) => {
  return (
    <>
      <Loading
        isLoading={isLoading}
        justify={justify}
        styles={styles?.loading}
        showLoadingText={showLoadingText}
        withTimeoutInfo={withTimeoutInfo}
      />
      <LoadedWrapper isLoading={isLoading} isError={isError}>
        {children}
      </LoadedWrapper>
      <ErrorLoading
        isFetching={isFetching}
        isError={isError}
        onTryAgain={refetch}
        styles={styles?.error}
        justify={justify}
      />
    </>
  );
};

ApiLoadingBoundary.displayName = 'ApiLoadingBoundary';

export default ApiLoadingBoundary;
